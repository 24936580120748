import React from 'react'
import { Certificates, Crew, Gallery, Page, Strucnost } from '@components'

const metaData = {
  title: 'about.seo.title',
  description: 'about.seo.description'
}

const b2b = props => {
  const { locale } = props.pageContext
  return (
    <Page locale={locale} meta={metaData}>
      <Strucnost />
      <Certificates />
      <Crew />
      <Gallery />
    </Page>
  )
}

export default b2b
